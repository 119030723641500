<template>
    <div class="mainx">
        <BaseHeader title="Liberação do saldo"></BaseHeader>
        <b-breadcrumb
            :items="[{ text: 'Dashboard', href: '/dashboard' }, { text: $t('views.seller.reports.text_1428'), href: '/relatorios' }, {text: 'Liberação do saldo'}]"
            class="bg-white m-0 p-0"></b-breadcrumb>
        <div class="divider"></div>
        <div>
            <Container title="Liberação de saldo diário" subtitle="Previsões de valores disponíveis para saque, dia a dia. Sujeito a alteração por chargebacks e reembolsos." :fullWidth="true">
                <Table :columns="['Data da liberação', 'Venda única', 'Assinatura', 'Valor a liberar']" :rows="data"></Table>
            </Container>
        </div>
    </div>
</template>

<script>
import BaseHeader from '@/components/BaseHeader.vue';
import Container from "@/components/Seller/Reports/Container.vue";
import Table from "@/components/Seller/Reports/Table.vue";
import ReportService from "@/services/resources/ReportService";
import moment from 'moment';
const Report = ReportService.build();

export default {
    components: {
        BaseHeader,
        Container,
        Table
    },
    data() {
        return {
            data: [],
            loading: false,
        };
    },
    methods: {
        fetchData() {
            this.loading = true;
            Report.search({ url: "sales-availability", startDate: moment().subtract(1, 'month').startOf('month').toISOString(), endDate: moment().endOf('year').toISOString(),  type: "full" })
                .then((response) => {
                    this.data = Object.values(response['sales_grouped_by_date']);
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e);
                });
        }
    },
    mounted() {
        this.fetchData();
    }
};
</script>

<style scoped>
</style>